<template>
  <div class="row">
    <div class="col-xs-12">
      <page-header
        title="Packages"
        :sub-heading="true"
        sub-header="Dashboard / Packages"
        link-name="Edit Package"
        link-url="/packages"
      >
      </page-header>
      <full-page-content-container>
        <div class="form">
          <div class="form__group">
            <label for="name" class="form__label">Package Name</label>
            <input
              readonly
              type="text"
              name="name"
              id="name"
              class="form__input readonly"
              placeholder="e.g Starter Package"
              v-model="formData.name"
            />
          </div>

          <div class="form__group">
            <label for="amount" class="form__label">Amount</label>
            <input
              readonly
              type="number"
              name="amount"
              id="amount"
              class="form__input readonly"
              placeholder="Specify package amount"
              v-model="formData.amount"
            />
          </div>

          <div class="form__group">
            <label for="interval" class="form__label">Interval</label>
            <input
              readonly
              name="interval"
              id="interval"
              class="form__input readonly" 
              v-model="formData.interval"
            />
              
          </div>

          <div class="form__group">
                <label for="btn_text" class="form__label">Details</label>
                <input
                  type="text"
                  name="btn_text"
                  id="btn_text"
                  class="form__input"
                  placeholder="e.g Pay Now"
                  v-model="formData.detail_field"
                  @input="getDetail"
                />
              </div>
              <div class="form__group">
                <div class="details">
                  <div class="details__item" v-for="(item, index) in formData.detail" :key="index">
                    <span>
                      <strong>
                        <span>{{ item }}</span>
                      </strong>
                    </span>
                    <span class="cancel">
                      <i v-if="formData.detail.length > 0" class="fa fa-times" aria-hidden="true" @click="remove(index)"></i>
                    </span>
                    
                  </div>
                </div>
              </div>

          <div class="form__group">
            <label for="btn_text" class="form__label">Button Text</label>
            <input
              type="text"
              name="btn_text"
              id="btn_text"
              class="form__input"
              placeholder="e.g Pay Now"
              v-model="formData.btn_text"
            />
          </div>

          <div class="form__group">
            <label for="user_type" class="form__label">User Type</label>
            <select
              name="user_type"
              id="user_type"
              class="form__input"
              v-model="formData.user_type"
            >
              <option value="startup" selected>Startup</option>
              <option value="investor">Investor</option>
            </select>
          </div>

          <div class="form__group">
            <ca-button :disabled="loading" @click.native="editPackage">
              <span>Create Package</span>
              <span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span>
            </ca-button>
          </div>
        </div>
      </full-page-content-container>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import axios from "../../../axios";
import CaButton from "../../components/buttons/CaButton";
import FullPageContentContainer from "../../layouts/components/fullPage/FullPageContentContainer";
export default {
  name: "EditPackage",
  components: { FullPageContentContainer, CaButton, PageHeader },
  data() {
    return {
      loading: false,
      formData: {
        id: this.$route.params.id,
        name: "",
        amount: "",
        interval: "year",
        btn_text: "",
        detail_field: "",
        detail:[],
        user_type: "startup",
      },
    };
  },

  methods: {
    getPackage() {
      const loading = this.$vs.loading();
      axios
        .get(
          `${this.$store.state.server.requestUri}/admin/packages/${this.$route.params.id}`
        )
        .then((res) => {
          loading.close();
          this.formData.name = res.data.data.name;
          this.formData.amount = res.data.data.amount;
          this.formData.interval = res.data.data.interval;
          this.formData.detail = JSON.parse(res.data.data.detail);
          this.formData.detail_field = this.formData.detail.join(", ");
          this.formData.btn_text = res.data.data.btn_text;
          console.log(res.data.data);
        })
        .catch((err) => {
          loading.close();
          console.log(err.response);
        });
    },
    editPackage() {
      const loading = this.$vs.loading();
      this.loading = true;
      axios
        .patch(
          `${this.$store.state.server.requestUri}/admin/packages/${this.$route.params.id}`,
          {
            name: this.formData.name,
            amount: this.formData.amount,
            interval: this.formData.interval,
            detail: this.formData.detail,
            btn_text: this.formData.btn_text,
            user_type: this.formData.user_type,
          }
        )
        .then((res) => {
          loading.close();
          this.loading = false;
          console.log(res);
          this.$toast("Package edited successfully", {
            type: "success",
            position: "top-center",
          });
          console.log(res);
        })
        .catch((err) => {
          loading.close();
          this.loading = false;
          console.log(err.response);
        });
    },
    getDetail() {
      this.formData.detail = this.formData.detail_field.split(",");
    },
    remove(index) {
      this.formData.detail.splice(index, 1);
      this.formData.detail_field = this.formData.detail.join(",");
    },
  },

  created() {
    this.getPackage();
  },
};
</script>

<style scoped>
.readonly {
  background-color: #f5f5f5;
  border: none;
}
.details{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  vertical-align: middle;
  background-color: #f5f5f5;
  padding: 10px;
  height: auto;

}

.details__item{
  padding: 10px 30px 10px 10px;; 
  background-color: #fff;
  border-radius: 5px;
  height: 50px;
  position: relative;
  margin-bottom: 10px;
  margin: 0 10px 10px 0;
  font-weight: lighter;

}
.cancel{
  position: absolute;
  right: 4px;
  top: 10px;
  cursor: pointer;
  z-index: 1;
}
</style>
